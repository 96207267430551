<template>
  <v-row>
    <v-col cols="12" md="12">
      <div id="map" style="height:300px;width:100%;"></div>
    </v-col>
  </v-row>
</template>
<script>
// import arcgisjs from './arcGisJs.js';
export default {
  name: "arcgis",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "ArcGis",
  }
//   ,
//   mounted: function () {
//     // arcgisjs.pleasework(this); // give pleasework a reference to the Vue instance
//   },
};
</script>
<script>
import { loadModules } from "esri-loader";
export default {
    mounted(){
        loadModules([
            "esri/Map",
            "esri/views/MapView"
        ], { css: true })
        .then(([Map,MapView]) => {
             let map = new Map({
                 basemap: "streets"
             });
             let view = new MapView({
                 container: "map",
                 map: map,
                 zoom: 4,
                 center: [15, 65]
             });
        });
    }
}
</script>
